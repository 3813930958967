export const AppActions = {
  REGISTRATION_CHECK: "REGISTRATION_CHECK",
  LANDING_PAGE_DATA: "LANDING_PAGE_CAPTURE_DATA",
  LANDING_PAGE_EMAIL: "LANDING_PAGE_SAVE_EMAIL",
  CUSTOMER_APPROVAL_STATUS: "CUSTOMER_APPROVAL_STATUS",
  TEXT_APPS_LINK: "TEXT_APPS_LINK",
  SEND_EVENT: "SEND_EVENT",
  LEGAL_AGREEMENT_FEES: "LEGAL_AGREEMENT_FEES",
  LEGAL_AGREEMENT_LOAN: "LEGAL_AGREEMENT_LOAN",
  LEGAL_AGREEMENT_ESIGN: "LEGAL_AGREEMENT_ESIGN",
};

export const AuthActions = {
  SUBMIT_TOKEN_ACTION: "SUBMIT_TOKEN_ACTION",
  LOGIN_ACTION: "LOGIN_ACTION"
};
