import authentication from "redux/slice/authentication";
import commonReducer from "redux/slice/commonSlice";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";
import { rootSaga } from "./redux/sagas";

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: {
    authentication,
    commonReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      sagaMiddleware
    ),
});
sagaMiddleware.run(rootSaga);

export default store;