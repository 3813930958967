import { call, put, takeEvery } from "redux-saga/effects";
import { credentialsSubmitted, tokenSubmitted } from "../slice/authentication";

import { AuthActions } from "../types";

function* submitToken(action) {
  try {
    yield put({ type: tokenSubmitted, payload: action.payload });
  } catch (e) {
     yield put({type: "submitToken", message: e.message});
  }
}

function* submitCredentials(action) {
  try {
    yield put({ type: credentialsSubmitted, payload: action.payload });
  } catch (e) {
     yield put({type: "submitCredentials", message: e.message});
  }
}


export function* watchAuthAsync() {
  yield takeEvery(AuthActions.SUBMIT_TOKEN_ACTION, submitToken);
  yield takeEvery(AuthActions.LOGIN_ACTION, submitCredentials);
}
