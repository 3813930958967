import * as React from 'react';

import AppsIcon from '@mui/icons-material/Apps';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusinessIcon from '@mui/icons-material/Business';
import Divider from '@mui/material/Divider';
import GridViewIcon from '@mui/icons-material/GridView';
import GroupsIcon from '@mui/icons-material/Groups';
import KeyIcon from '@mui/icons-material/Key';
import List from '@mui/material/List';
import { ListItem } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import SettingsIcon from '@mui/icons-material/Settings';
import SupportIcon from '@mui/icons-material/Support';
import WebhookIcon from '@mui/icons-material/Webhook';
import { useNavigate } from "react-router";

const menuItemList = [
  {
    key: 'home',
    name: 'Home',
    icon: <GridViewIcon />,
    isSection: false,
    sectionName: '',
    isExpandable: false,
    isExpanded: true,
    isDivider: false
  },
  {
    key: 'config',
    name: 'Configurations',
    icon: <SettingsIcon />,
    isSection: false,
    sectionName: '',
    isExpandable: false,
    isExpanded: true,
    isDivider: false
  }, {
    key: 'brand',
    name: 'Branding',
    icon: <BusinessIcon />,
    isSection: false,
    sectionName: '',
    isExpandable: false,
    isExpanded: true,
    isDivider: false
  },
  {
    key: 'support',
    name: 'Support',
    icon: <SupportIcon />,
    isSection: false,
    sectionName: '',
    isExpandable: false,
    isExpanded: true,
    isDivider: false
  },
  {
    key: 'reports',
    name: 'Reports',
    icon: <AssessmentIcon />,
    isSection: false,
    sectionName: '',
    isExpandable: false,
    isExpanded: true,
    isDivider: false
  },
  {
    key: 'divider_1',
    name: '',
    icon: <Divider />,
    isSection: false,
    sectionName: '',
    isExpandable: false,
    isExpanded: true,
    isDivider: true
  },
  {
    key: 'section_1',
    name: '',
    icon: <Divider />,
    isSection: true,
    sectionName: 'Application',
    isExpandable: false,
    isExpanded: true,
    isDivider: false
  },
  {
    key: 'keys',
    name: 'Keys',
    icon: <KeyIcon />,
    isSection: false,
    sectionName: '',
    isExpandable: false,
    isExpanded: true,
    isDivider: false
  },
  {
    key: 'logs',
    name: 'Logs',
    icon: <AssignmentIcon />,
    isSection: false,
    sectionName: '',
    isExpandable: false,
    isExpanded: true,
    isDivider: false
  },
  {
    key: 'webhooks',
    name: 'Webhooks',
    icon: <WebhookIcon />,
    isSection: false,
    sectionName: '',
    isExpandable: false,
    isExpanded: true,
    isDivider: false
  },
  {
    key: 'section_2',
    name: '',
    icon: <Divider />,
    isSection: true,
    sectionName: 'API Docs',
    isExpandable: false,
    isExpanded: true,
    isDivider: false
  },
  {
    key: 'change_logs',
    name: 'Change Logs',
    icon: <AppsIcon />,
    isSection: false,
    sectionName: '',
    isExpandable: false,
    isExpanded: true,
    isDivider: false
  },
  {
    key: 'divider_2',
    name: '',
    icon: <Divider />,
    isSection: false,
    sectionName: '',
    isExpandable: false,
    isExpanded: true,
    isDivider: true
  },
  {
    key: 'invite_teams',
    name: 'Invite Teams',
    icon: <GroupsIcon />,
    isSection: false,
    sectionName: '',
    isExpandable: false,
    isExpanded: true,
    isDivider: false
  },
];

export default function MenuItems() {

  const handleClick = (index, name, key) => {
    goToPage(key);
  }

  const navigate = useNavigate()

  const goToPage = (page) => {
    if (page === 'home') {
      navigate('/dashboard', { replace: true, state: {} })
    } else if (page === 'config') {
      navigate('/config', { replace: true, state: {} })
    } else if (page === 'brand') {
      navigate('/branding', { replace: true, state: {} })
    }else if (page === 'support') {
      navigate('/support', { replace: true, state: {} })
    }else if (page === 'reports') {
      navigate('/reports', { replace: true, state: {} })
    }else if (page === 'keys') {
      navigate('/keys', { replace: true, state: {} })
    }else if (page === 'logs') {
      navigate('/logs', { replace: true, state: {} })
    }else if (page === 'webhooks') {
      navigate('/webhooks', { replace: true, state: {} })
    }else if (page === 'change_logs') {
      navigate('/change_logs', { replace: true, state: {} })
    }else if (page === 'invite_teams') {
      navigate('/invite_teams', { replace: true, state: {} })
    }
  };

  const mainListItems = (
    <React.Fragment>
      {menuItemList.map((item, index) => {
        if (item.isDivider === true)
          return (
            <Divider key={item.key} />
          )
        else if (item.isSection === true) {
          return (
            <ListItem key={item.key} disablePadding>
              {item.icon}
              <ListSubheader component="div" inset  >
                {item.sectionName}
              </ListSubheader>
              <Divider />
            </ListItem>
          )
        } else if (item.isSection === false) {
          return (
            <ListItem key={item.key} disablePadding onClick={() => handleClick(index, item.name, item.key)}>
              <ListItemButton>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
          )
        }
      })}
    </React.Fragment>
  );

  return (<List component="nav">
    {mainListItems}
  </List>);
}