class TokenService {

  getPartnerToken() {
    let token;
    if (window.localStorage.getItem("partner_token")) {
      if (window.localStorage.getItem("partner_token").charAt(0) == '"') {
        token = JSON.parse(localStorage.getItem("partner_token"));
      } else {
        token = localStorage.getItem("partner_token");
      }
    }
    return token;
  }

  updatePartnerToken(token) {
    window.localStorage.setItem("partner_token", token);
  }

  getSharedSecret() {
    let token;
    if (window.localStorage.getItem("shared_secret")) {
      if (window.localStorage.getItem("shared_secret").charAt(0) == '"') {
        token = JSON.parse(localStorage.getItem("shared_secret"));
      } else {
        token = localStorage.getItem("shared_secret");
      }
    }
    return token;
  }

  updateSharedSecret(token) {
    window.localStorage.setItem("shared_secret", token);
  }
}

export default new TokenService();