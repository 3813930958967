import * as React from 'react';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { Grid } from "@mui/material";
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';

function Copyright() {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <Typography variant="body2" color="text.secondary">
          {'Copyright © '}
          <Link color="inherit" href="https://www.growcredit.com/">
            Grow Credit, Inc.
          </Link>{'   '}
          {new Date().getFullYear()}
          {''}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" color="text.secondary">
          {'All Rights Reserved'}
        </Typography>
      </Grid>
    </Grid>
  );
}

const defaultTheme = createTheme();

export default function StickyFooter() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        backgroundColor: "secondary.main",
        paddingTop: "1rem",
        paddingBottom: "1rem",
      }}
    >
      <Container maxWidth="lg">
        <Container>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <a href="/">
              <img
                alt="logo"
                src="https://growcredit-assets.s3.us-west-2.amazonaws.com/logo_full_light.png"
                width={120}
                style={{ marginBottom: "16px", marginRight: "8px" }}
              />
            </a>
          </Grid>
        </Container>
        <Copyright />
      </Container>
    </Box>
  );
}
