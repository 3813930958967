import PropTypes from "prop-types";
import React from "react";

const AuthGuard = (props) => {
  const { children } = props;
  const BASE_URL = process.env.REACT_APP_BASE_URL.slice(0, -3);

  //TODO: Uncomment when live
  // if (!window.localStorage.getItem("user_token")) {
  //   window.location.href = BASE_URL;
  // } else {
    return <>{children}</>;
 // }

  return <></>
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
