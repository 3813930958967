import App from "./App";
import {AppProvider} from "./contexts/AppContext";
import {AuthProvider} from "./contexts/AuthTokenContext";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
  <BrowserRouter>
    <AppProvider>
      <AuthProvider>
        <App/>
      </AuthProvider>
    </AppProvider>
  </BrowserRouter>
</Provider>
);