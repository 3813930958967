import * as React from "react";

import AppBar from "components/AppBar";
import CssBaseline from '@mui/material/CssBaseline';
import CustomLoader from "components/custom/CustomLoader";
import StickyFooter from "components/StickyFooter";
import { ThemeProvider } from "@mui/material";
import routes from "./routes";
import theme from "./theme";
import useAuth from "./hooks/useAuth.js";
import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";

const App = () => {
  const content = useRoutes(routes);
  // localStorage.setItem(
  //   "user_token",
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6IjM0N2IwNWJmNjcyYzhlMjg2MmU3ZjkzYWRmOGQwZjRkYTI3NTQ0YjJhOWFiNGU3MWQ1ZWQ2ZTgwYmE5NTE5MmJhYmNlNTYzYzQzYWIwZDY2In0.eyJhdWQiOiIyMSIsImp0aSI6IjM0N2IwNWJmNjcyYzhlMjg2MmU3ZjkzYWRmOGQwZjRkYTI3NTQ0YjJhOWFiNGU3MWQ1ZWQ2ZTgwYmE5NTE5MmJhYmNlNTYzYzQzYWIwZDY2IiwiaWF0IjoxNjU1MzgwNDU1LCJuYmYiOjE2NTUzODA0NTUsImV4cCI6MTY4NjkxNjQ1NSwic3ViIjoiMzY3NyIsInNjb3BlcyI6W119.DEi4ZEjsb_XpXEbOvlNfUTte2yYN4qPzrfoOIQ3ehmEgT5hwxrJnXBAW5B3AC4wwSKHtRtcoNynWpR0tifrOSvPWH4xnku8ZEIyG503dbevQU9HKmITPZPpgKrVfT7sqJyQYFC2WJ8SiKgM6GGx4oyB1VNfheNlr8mjwGgL3_GN7nMifXzDdKeo-KhrASFeeJRgCPPcMlnMoYb02dFRa5alecYf6JaUDWpcFuCKzgy_hhIOSEUOLVjaJprYhz7dyvx5qCuS_mgz1j_kyLHKDqrMCFeY0fhh2keYrPg-Ij2uB_nXg6GGwnc5s02aSJ-nqG6HHRZkghxaByBdM9TYibz7jfuiZuYVrKwfOOE8Jpn2hkl3nDsd1vJsxZuRf5Vy7-WyYtoQk3k3Mk4ICNcfTlRLxnfr14KLnBOQNxWvWjfeMG2gHDO5m-gVUQ0UlzQfNVbjaZbf4iLZbYHsPB91xKE6Ba0IG-dT_fyPCzqUHxKtUCqgE-fJibLsyn9ZscM7rqoBJ0301KjY2WmGIPy88bTydHUXbO3_cRy_YxDV2N9YJOCOTGrjg46lAtNouYx05P4TbfqyULo8AzLpf0ROT5n-y-5hzXcDq9UOvGME_Xll3GHMZ1nUnR7dJ9ZHnxrPZwAnNVGdiqi1Hhj4WZJrxyO_IYNs78cl9TY7Tj41jvbU"
  // );
  const auth = useAuth();
  const { isAuthenticated } = auth;
  // const BASE_URL = process.env.REACT_APP_BASE_URL.slice(0, -3);
  // if (isAuthenticated === false) {
  //   window.location.href = BASE_URL + "signin?source=" + window.location.origin;
  // }
  const { isLoading } = useSelector((state) => state.commonReducer);
  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <AppBar widget={content} />
        {isLoading ? <CustomLoader /> : null}
      </ThemeProvider>
    </>
  );
};
export default App;
