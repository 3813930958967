import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = responsiveFontSizes(
  createTheme({
    spacing: 4,
    typography: {
      fontFamily: ["Lato","Roboto", "Raleway", "Open Sans"].join(","),
      h1: {
        fontSize: "5rem",
        fontFamily: "Raleway",
      },
      h2: {
        fontSize: "3.5rem",
        fontFamily: "Open Sans",
        fontStyle: "bold",
      },
      h3: {
        fontSize: "2.5rem",
        fontFamily: "Roboto",
      },
      button: {
        textTransform: "none",
      },
    },
    palette: {
      background: {
        default: "#009900", //green
      },
      primary: {
        main: "#6B35EE", //"#0070ba", //indigo
      },
      secondary: {
        main: "#444e73", //pink
      },
      error: {
        main: "#D72A2A", //red
      },
      warning: {
        main: "#FFA500", //orange
      },
      info: {
        main: "#6B7D6A", //gray
      },
      success: {
        main: "#09FE00", //green
      },
      text: {
        primary: "#28335B",
        secondary: "#f6f7fa",
        light: "rgba(24, 33, 66, 0.06)",
        button: "#6B35EE", //"#0070ba",
      },
    },
  })
);

export default theme;
