import { Suspense, lazy } from "react";

import AuthGuard from "./components/custom/AuthGuard";
import LoadingScreen from "./components/LoadingScreen";

export const Loadable = (Component) => (props) =>
(
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const SignIn = Loadable(lazy(() => import("./pages/SignIn")));

const routes = [
  {
    path: "*",
    element: <AuthGuard><SignIn /></AuthGuard>,
    children: [],
  },
  {
    path: "signin",
    element: <AuthGuard><SignIn /></AuthGuard>,
    children: [],
  }
];

export default routes;
