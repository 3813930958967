import { decode as base64_decode, encode as base64_encode } from "base-64";

import Base64 from "crypto-js/enc-base64";
import { createSlice } from "@reduxjs/toolkit";
import token_service from "components/token_service";

const initialState = {
  partnerAccessToken: token_service.getPartnerToken(),
  customerCredentials: "",
  shouldRefreshWidget: false,
  sharedSecret: token_service.getSharedSecret(),
};

const authentication = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    tokenSubmitted: (state, action) => {
      console.log(`${action.payload.partner_token}`);
      state.partnerAccessToken = action.payload.partner_token;
      state.sharedSecret = action.payload.shared_secret;
      state.shouldRefreshWidget = true;
      return state;
    },
    credentialsSubmitted: (state, action) => {
      state.partnerAccessToken = action.payload.partner_token;
      state.sharedSecret = action.payload.shared_secret;
      if (action.payload.email != "" && action.payload.password != "") {
        let encoded = base64_encode(
          `${action.payload.email}%${action.payload.password}`
        );
        state.customerCredentials = encoded;
      } else {
        state.customerCredentials = "";
      }
      state.shouldRefreshWidget = true;
      return state;
    },
    reset: (state, action) => {
      //state.partnerAccessToken = "";
      //state.customerCredentials = "";
      //state.sharedSecret = "";
      state.shouldRefreshWidget = false;
      return state;
    },
  },
});
export const { tokenSubmitted, credentialsSubmitted, reset } =
  authentication.actions;
export default authentication.reducer;
