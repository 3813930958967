import React, { createContext, useEffect, useState } from "react";
import { setLoading, setQueryParams } from "redux/slice/commonSlice";
import { useDispatch, useSelector } from "react-redux";

import { AppActions } from "redux/types";

const AppContext = createContext();

export const AppProvider = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const [isRegistrationAllowed, setIsRegistrationAllowed] = useState(false);
  const { registrationAllowed, landingPageGUID } = useSelector((state) => state.commonReducer);

  useEffect(() => {
    //dispatch(setLoading(true));
    // dispatch({
    //   type: AppActions.REGISTRATION_CHECK,
    // });
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const value = parameters.get("token");
    if(!value) {
      const params = Object.fromEntries(parameters.entries());
      if(Object.keys(params).length > 0) {
        if(params._ref) {
          dispatch(setQueryParams({_ref: params._ref}));
        }
        if(params.affiliate_link) {
          dispatch(setQueryParams({affiliate_link: params.affiliate_link}));
        }
        if(params.affiliate_ptc) {
          dispatch(setQueryParams({affiliate_ptc: params.affiliate_ptc}));
        }
        if(params.affiliate_id) {
          dispatch(setQueryParams({affiliate_id: params.affiliate_id}));
        }
        if(params.affiliate_user_agent) {
          dispatch(setQueryParams({affiliate_user_agent: params.affiliate_user_agent}));
        }
        if(params.customer_referral_reward_type) {
          dispatch(setQueryParams({customer_referral_reward_type: params.customer_referral_reward_type}));
        }
        if(params.irclickid && params.ir_partnerId) {
          dispatch(setQueryParams({impact_click_id: params.irclickid, impact_partner_id: params.ir_partnerId}));
        }
        if(landingPageGUID === "") {
          dispatch({
            type: AppActions.LANDING_PAGE_DATA,
            payload: params
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if(registrationAllowed) {
      setIsRegistrationAllowed(registrationAllowed);
      dispatch(setLoading(false));
    }
  }, [registrationAllowed]);

  return (
    <AppContext.Provider
      value={{
        isRegistrationAllowed,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
