import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "commonReducer",
  initialState: {
    isLoading: false,
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
      //   return state;
    },
    setQueryParams: (state, action) => {
      if (action.payload) {
        state.queryParams = { ...state.queryParams, ...action.payload };
      }
      return state;
    },
  },
});
export const { setLoading, setQueryParams } = slice.actions;
export default slice.reducer;
